.nav-bar {
  animation: fadeIn 1s cubic-bezier(0.05, 0.82, 0.65, 0.96);
  position: sticky;
  top: 10px;
  z-index: 100;
}

@keyframes fadeIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 100%;
  }
}
