.image-nav-container {
  height: 100vh;
  width: 100vw;
}

.image-nav-container .scroll-container {
  display: flex;
  width: 500%; /* 5 sections * 100% */
  height: 100%;
  flex-wrap: nowrap;
}

.scroll-container .section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  color: white;
  min-width: 100vw;
  height: 100%;
}
.scroll-container .section:nth-child(1) {
  background-color: rgb(84, 157, 120);
}
.scroll-container .section:nth-child(2) {
  background-color: rgb(41, 73, 57);
}
.scroll-container .section:nth-child(3) {
  background-color: rgb(126, 177, 152);
}
.scroll-container .section:nth-child(4) {
  background-color: rgb(41, 73, 57);
}
.scroll-container .section:nth-child(5) {
  background-color: rgb(84, 157, 120);
}
